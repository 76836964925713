@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900");
body {
  margin: 0;
  padding: 0;
}

#root{
  font-family: "Montserrat"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
