.ant-modal-footer {
  display: none;
}

.TitleContainer {
  background-color: #19232c;
  background-image: url("assets/background.png");
  background-size: cover;
  background-position-x: 6%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.TextContainer {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.ComingSoonContainer {
  background-color: #19232c;
  background-image: url("assets/coming-soon.jpg");
  background-size: cover;
  background-position-x: 90%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.NewsletterContainer {
  background-color: #19232c;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-link {
  color: #09d3ac;
}

.ant-form-item {
  margin-bottom: 10px;
}

.LogoBlack {
  width: 8%;
}

.LogoWhite {
  position: relative;
  width: 5rem;
  height: 100%;
}

//Ticket 111
.ant-btn.FooterLinks {
  padding: 0 5px;
}
.ant-btn-background-ghost.FooterIcons {
  font-size: 18px;
}
.AccountBodyWhite {
  background-color: #ffffff;
  height: 150px;
}
.AccountBodyGrey {
  background-color: #e4e4e4;
  height: 150px;
}
.AccountDescription {
  text-align: left;
}

.ScrollableBox {
  height: 74vh;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .AccountBodyWhite {
    height: 250px;
  }
  .AccountBodyGrey {
    height: 250px;
  }
  .AccountDescription {
    text-align: center;
  }
  .AccountText {
    font-size: 12px;
  }
}

//Activationpages
//white pop up background:
.popUp {
  width: auto;
  background-color: white;
  position: relative;
  height: 74vh;
}
//blue bar in the background:
.BackgroundAP {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  left: 30%;
}
//white shoe:
.Activation {
  max-width: 70%;
  height: 150%;
  position: absolute;
  z-index: 1;
  top: -22%;
  left: 40%;
}

.footerLogo {
  float: right;
  width: 18vh;
}

.login-form-forgot {
  margin-left: 90px;
}

.ant-checkbox-inner {
  background-color: white;
  border-color: black;
  border-radius: 50%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

.has-error .ant-input.EmailBar {
  box-shadow: none;
}

.LogoMobil {
  height: auto;
  margin: auto;
  display: none;
}

.MailGesendet {
  padding-right: 70vh;
}

//Activationpages
// TODO: refactor this, to use standard media query length. or change layout to use rows & cols
@media screen and (max-width: 1250px) {
  .BackgroundAP {
    display: none;
  }
  .Activation {
    display: none;
  }
  .MailGesendet {
    padding-right: 10vh;
  }
}

// This will disable the "not-allowed"-cursor by mouseover the disabled slider.
// We found no other method to switch the cursor back to normal.
// The "not-allowed"-cursor would suggest the user that he can interact with the slider
// However the slider is fix with a value provided by the API
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: default !important;
}

.ant-slider-disabled {
  cursor: default !important;
}

.ant-slider-disabled .ant-slider-handle {
  cursor: default !important;
}

@media screen and (max-width: 768px) {
  .BackgroundAP {
    display: flex;
    left: -79%;
    margin-top: 92%;
  }
  .footerLogo {
    display: none;
  }
  .MailGesendet {
    padding-right: 10vh;
    padding-left: 10vh;
  }
  .popUp {
    width: 100%;
    text-align: center;
  }

  .emailbox {
    width: 30vh;
    font-size: 14.64px;
  }
  .ant-btn-icon-only {
    display: none;
  }
  .LogoMobil {
    width: 35%;
    margin-top: 30%;
    display: flex;
  }
  .login-form-forgot {
    margin-left: 0;
  }
  .ScrollableBox {
    overflow: hidden;
    height: auto;
  }
  @media (max-width: 576px) {
    .MailGesendet {
      padding-right: 0vh;
      padding-left: 0vh;
    }
    .ButtonMargin {
      margin-left: 0;
    }
  }
  //Landingpage
  .LogoBlack {
    width: 20%;
    margin-bottom: 1em;
  }

  .LogoWhite {
    position: relative;
    width: 90px;
    height: 100%;
    padding-bottom: 60px;
    padding-top: 20px;
  }

  .TitleContainer {
    p {
      font-size: 43.2px;
      line-height: 1;
    }
  }

  .TextContainer {
    min-height: 100vh;
    p {
      line-height: 300%;
      font-size: 10.72px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .ComingSoonContainer {
    p {
      font-size: 260%;
    }
  }

  .ant-layout-footer {
    font-size: 11px;
  }

  .ant-btn-background-ghost {
    font-size: 11px;
  }
}

// Ticket 111
@media (min-width: 768px) {
  .ant-btn.FooterLinks {
    padding: 0 15px;
  }

  .AccountText {
    text-align: left;
  }
}
